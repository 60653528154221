import type { OrderProductOptions } from "../OrderProductOptions.js";
import type { ProductSize } from "../ProductSize.js";

export function createEmptyOptions(size: ProductSize): OrderProductOptions {
	return {
		numbers: [],
		extraFruit: false,
		glutenFree: false,
		lactoseFree: false,
		meringues: false,
		creamColor: undefined,
		labels: [],
		lyingPhoto: undefined,
		standingPhotos: [],
		size,
		macaroons: undefined,
		figurines: [],
	};
}
